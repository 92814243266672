import {
  getStyleSynopAvailability,
  getStyleGbonSynopAvailability,
  getStyleSynopQuality,
  getStyleSynopMonthlyQuality
} from "./mapStyles/synop";
import {
  getStyleTempAvailabilitySixhour,
  getStyleTempAvailabilityDaily,
  getStyleTempAvailabilityMonthly,
  getStyleGbonTempAvailabilityDaily,
  getStyleGbonTempAvailabilityMonthly,
  getStyleTempQuality
} from "./mapStyles/temp";
import {
  getStyleGuanAvailability,
  getStyleGuanQuality
} from "./mapStyles/guan";
import { styleGsnAvailability, getStyleGsnCompleteness } from "./mapStyles/gsn";
import {
  LegendSynopAvailability,
  LegendSynopAvailabilityMonthly,
  LegendSynopAvailabilityGbonSixhour,
  LegendSynopAvailabilityGbonDaily,
  legendSynopGbonMonthly,
  LegendSynopQualitySurfacePressure,
  LegendSynopQualityWind,
  LegendSynopQualityGeopotential,
  LegendSynopQualityTemperature,
  LegendSynopQualityHumidity,
  LegendSynopQualitySurfacePressureMonthly,
  LegendSynopQualityWindMonthly,
  LegendSynopQualityGeopotentialMonthly,
  LegendSynopQualityTemperatureMonthly,
  LegendSynopQualityHumidityMonthly,
  LegendSynopAlertQualityWind,
  LegendSynopAlertQualityHumidity,
  LegendSynopAlertQualitySurfacePressure,
  LegendSynopAlertQualityTemperature,
  LegendSynopAlertQualityGeopotential
} from "./mapLegends/synop";
import {
  LegendTempAvailabilitySixHour,
  LegendTempAvailabilityDaily,
  LegendTempAvailabilityMonthly,
  LegendTempQualityHumidity,
  LegendTempQualityHumidityMonthly,
  LegendTempQualityWind,
  LegendTempQualityWindMonthly,
  LegendTempQualityTemperature,
  LegendTempQualityTemperatureMonthly,
  LegendTempAvailabilityDailyGbon,
  LegendTempAvailabilityMonthlyGbon,
  legendTempMonthlyGbon
} from "./mapLegends/temp";

import {
  LegendGuanAvailability,
  LegendGuanQualityHumidity,
  LegendGuanQualityWind,
  LegendGuanQualityTemperature
} from "./mapLegends/guan";

import {
  LegendGsnAvailability,
  getLegendGsnCompleteness
} from "./mapLegends/gsn";

let key;
// Both MapBox keys belong to WMO.
// We use 1 key for local development and another one for the 3 online instances
// Adding apps.ecmwf.local
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "apps.ecmwf.local"
) {
  key =
    "pk.eyJ1Ijoia3VydC1oZWN0aWMiLCJhIjoiY2loNGtsNzc0MHpvdHgxbTBxejQzOGVpZiJ9.NiOdnMqDcZKeR6Oxo7tWmw";
} else {
  key =
    "pk.eyJ1Ijoia3VydC1oZWN0aWMiLCJhIjoiY2p3MHJqa2x6MGQydDQzbnZxNXBxNTBjbiJ9.fB_8rFPX1FrtRNAaXPaimg";
}

export const MAPBOX_KEY = key;
export const MAPBOX_BACKGROUND =
  "mapbox://styles/kurt-hectic/cj488lfn91qxh2rrz0nghg0y3";

// Stroke - only used for SYNOP/alert & added recently for monthly>Synop>Quality
export const makeStroke = (periodType, selectedReport, fileType) => {
  let stroke = 0;
  if (periodType === "alert") {
    stroke = [
      "case",
      ["==", ["get", "nb_obs"], 5],
      0,
      ["<", ["get", "nb_obs"], 5],
      1,
      7 // To make it easier to spot case > 5 (should not happen)
    ];
  } else if (periodType === "monthly" && selectedReport === "quality") {
    stroke = [
      "case",
      ["==", ["get", "nr_full_days"], 10],
      0,
      ["<", ["get", "nr_full_days"], 10],
      1,
      0 // To make it easier to spot case > 5 (should not happen)
    ];
  } else if (
    periodType === "monthly" &&
    selectedReport === "availability" &&
    fileType !== "temp"
  ) {
    stroke = [
      "case",
      ["==", ["get", "nr_full_days"], 10],
      0,
      ["==", ["get", "nr_received"], 0],
      0,
      ["<", ["get", "nr_full_days"], 10],
      1,
      0 // To make it easier to spot case > 5 (should not happen)
    ];
  }
  return stroke;
};

export const getMapStyle = (
  prefix,
  file_type,
  report,
  variable,
  period_type,
  date,
  baseline
) => {
  let style;
  if (
    file_type === "synop" ||
    file_type === "buoy" ||
    file_type === "ship" ||
    file_type === "marine_surface"
  ) {
    if (report === "availability") {
      if (baseline === "GBON" && prefix !== "") {
        style = getStyleGbonSynopAvailability(baseline, period_type);
      } else {
        style = getStyleSynopAvailability(baseline, period_type);
      }
    } else {
      // Quality
      if (period_type === "monthly") {
        style = getStyleSynopMonthlyQuality(variable);
      } else {
        style = getStyleSynopQuality(variable);
      }
    }
  } else if (file_type === "temp") {
    if (report === "availability") {
      if (baseline === "GBON" && prefix !== "") {
        if (period_type === "daily") {
          style = getStyleGbonTempAvailabilityDaily(baseline);
        } else {
          style = getStyleGbonTempAvailabilityMonthly(baseline);
        }
      } else if (period_type === "six_hour") {
        // style = styleTempAvailabilitySixHour;
        style = getStyleTempAvailabilitySixhour(baseline);
      } else if (period_type === "daily") {
        style = getStyleTempAvailabilityDaily(baseline);
      } else if (period_type === "monthly") {
        style = getStyleTempAvailabilityMonthly(baseline);
      }
    } else {
      // Quality
      style = getStyleTempQuality(variable);
    }
  } else if (file_type === "guan") {
    if (report === "availability") {
      style = getStyleGuanAvailability(report);
    } else {
      // Quality
      style = getStyleGuanQuality(variable);
    }
  } else if (file_type === "gsn") {
    if (report === "availability") {
      style = styleGsnAvailability;
    } else {
      // Completeness
      style = getStyleGsnCompleteness(variable, date);
    }
  }
  return style;
};

export const getMapLegend = (
  prefix,
  file_type,
  report,
  variable,
  period_type,
  date,
  baseline
) => {
  let legend;
  if (
    file_type === "synop" ||
    file_type === "buoy" ||
    file_type === "ship" ||
    file_type === "marine_surface"
  ) {
    if (report === "availability") {
      if (baseline === "GBON" && prefix !== "") {
        // GBON
        if (period_type === "six_hour") {
          legend = LegendSynopAvailabilityGbonSixhour;
        } else if (period_type === "daily") {
          legend = LegendSynopAvailabilityGbonDaily;
        } else {
          // legend = LegendSynopAvailabilityGbonMonthly;
          legend = legendSynopGbonMonthly(date);
        }
      } else {
        if (period_type === "monthly") {
          legend = LegendSynopAvailabilityMonthly;
        } else {
          legend = LegendSynopAvailability;
        }
      }
    } else if (period_type !== "alert") {
      // Quality
      if (report === "quality" && period_type === "monthly") {
        if (variable === "110") {
          legend = LegendSynopQualitySurfacePressureMonthly;
        } else if (variable === "39") {
          legend = LegendSynopQualityTemperatureMonthly;
        } else if (variable === "41" || variable === "42") {
          legend = LegendSynopQualityWindMonthly;
        } else if (variable === "58") {
          legend = LegendSynopQualityHumidityMonthly;
        } else {
          // Geopotential (variable = 1)
          legend = LegendSynopQualityGeopotentialMonthly;
        }
      } else if (variable === "110") {
        legend = LegendSynopQualitySurfacePressure;
      } else if (variable === "39") {
        legend = LegendSynopQualityTemperature;
      } else if (variable === "41" || variable === "42") {
        legend = LegendSynopQualityWind;
      } else if (variable === "58") {
        legend = LegendSynopQualityHumidity;
      } else {
        // Geopotential (variable = 1)
        legend = LegendSynopQualityGeopotential;
      }
    } else {
      // Alert
      if (variable === "110") {
        legend = LegendSynopAlertQualitySurfacePressure;
      } else if (variable === "39") {
        legend = LegendSynopAlertQualityTemperature;
      } else if (variable === "41" || variable === "42") {
        legend = LegendSynopAlertQualityWind;
      } else if (variable === "58") {
        legend = LegendSynopAlertQualityHumidity;
      } else {
        // Geopotential (variable = 1)
        legend = LegendSynopAlertQualityGeopotential;
      }
    }
  } else if (file_type === "temp") {
    if (report === "availability") {
      if (baseline === "GBON" && prefix !== "") {
        if (period_type === "six_hour") {
          legend = LegendTempAvailabilityDailyGbon;
        } else if (period_type === "daily") {
          legend = LegendTempAvailabilityDailyGbon;
        } else if (period_type === "monthly") {
          // legend = LegendTempAvailabilityMonthlyGbon;
          legend = legendTempMonthlyGbon(date);
        }
      } else if (period_type === null || period_type === "six_hour") {
        legend = LegendTempAvailabilitySixHour;
      } else if (period_type === "daily") {
        legend = LegendTempAvailabilityDaily;
      } else if (period_type === "monthly") {
        legend = LegendTempAvailabilityMonthly;
      }
    } else {
      // Quality
      if (variable === "2") {
        legend = LegendTempQualityTemperature;
        if (period_type === "monthly") {
          legend = LegendTempQualityTemperatureMonthly;
        }
      } else if (variable === "3" || variable === "4") {
        legend = LegendTempQualityWind;
        if (period_type === "monthly") {
          legend = LegendTempQualityWindMonthly;
        }
      } else {
        // Relative humidity (variable = 29)
        legend = LegendTempQualityHumidity;
        if (period_type === "monthly") {
          legend = LegendTempQualityHumidityMonthly;
        }
      }
    }
  } else if (file_type === "guan") {
    if (report === "availability") {
      legend = LegendGuanAvailability;
    } else {
      // Quality
      if (variable === "humidity") {
        legend = LegendGuanQualityHumidity;
      } else if (variable === "wind") {
        legend = LegendGuanQualityWind;
      } else if (variable === "temperature") {
        legend = LegendGuanQualityTemperature;
      }
    }
  } else if (file_type === "gsn") {
    if (report === "availability") {
      legend = LegendGsnAvailability;
    } else {
      legend = getLegendGsnCompleteness(variable, date);
    }
  }
  return legend;
};

// Used to to remove the class "unselected" on the legend items when adding a new layer
export const clearLegend = () => {
  const legendItems = document.getElementsByClassName("legend-class-clickable");
  for (let i = 0; i < legendItems.length; i++) {
    legendItems[i].classList.remove("unselected");
  }
};

// Used by the function `editMapAttribution` below
// to get the name of the variable instead of its code
const SYNOPVariables = {
  "110": "surface pressure",
  "1": "geopotential",
  "39": "2m temperature",
  "41": "10m zonal wind component",
  "42": "10m meridional wind component",
  "58": "2m relative humidity"
};

const TEMPVariables = {
  "2": "temperature",
  "3": "zonal wind component",
  "4": "meridional wind component",
  "29": "relative humidity"
};

const GCOSVariables = {
  guan: {
    temperature: "temperature",
    wind: " wind",
    humidity: "humidity"
  },
  gsn: {
    pressure: "pressure",
    temperature: "temperature",
    max_temperature: "max temperature",
    min_temperature: "min temperature",
    watervapor: "water vapor",
    precipitation: "precipitation",
    sunshine_duration: "sunshine duration"
  }
};

// This function displays in the map's attribution
// (in the bottom right corner) all the paramaters
// that correspond to the layer currently on the map.
// If no attribute is passed, the attribution is an
// empty string (this is used when initializing the map)
export const editMapAttribution = (
  fileType,
  baseline,
  selectedPeriodType,
  selectedReport,
  selectedVariable,
  selectedCenter,
  selectedDate,
  selectedSixHPeriod
) => {
  const attrib = document.getElementsByClassName(
    "mapboxgl-ctrl mapboxgl-ctrl-attrib"
  )[0];

  if (!selectedPeriodType) {
    attrib.innerHTML = "";
  }
  let dataType;
  if (fileType === "synop" || fileType === "gsn") {
    dataType = "surface";
  } else if (fileType === "temp" || fileType === "guan") {
    dataType = "upper-air";
  } else if (
    fileType === "buoy" ||
    fileType === "ship" ||
    fileType === "marine_surface"
  ) {
    dataType = "Surface";
  }

  if (
    fileType === "synop" ||
    fileType === "temp" ||
    fileType === "buoy" ||
    fileType === "ship" ||
    fileType === "marine_surface"
  ) {
    let date = selectedDate;
    if (selectedPeriodType === "six_hour") {
      date = selectedDate + " " + selectedSixHPeriod;
    }
    let variable = SYNOPVariables[selectedVariable];
    if (fileType === "temp") {
      variable = TEMPVariables[selectedVariable];
    }
    let attributeType = "NWP";
    if (baseline === "GBON") {
      attributeType = "GBON";
    } else if (
      fileType === "buoy" ||
      fileType === "ship" ||
      fileType === "marine_surface"
    ) {
      attributeType = "Marine";
    }

    attrib.innerHTML = `${dataType} ${attributeType} | ${selectedPeriodType.replace(
      "_",
      "-"
    )} | ${selectedReport} | ${variable} | ${selectedCenter} | ${date}
  `;

    if (fileType === "temp" && selectedReport === "availability") {
      attrib.innerHTML = `${dataType} ${attributeType} | ${selectedPeriodType.replace(
        "_",
        "-"
      )} | ${selectedReport} | ${selectedCenter} | ${date}
  `;
    }
  } else if (fileType === "guan" || fileType === "gsn") {
    const d = new Date(selectedDate);
    const dateMonth = `${shortMonthNames[d.getMonth()]} ${d.getFullYear()}`;
    if (selectedReport === "availability") {
      attrib.innerHTML = `${dataType} GCOS | ${selectedReport} | DWD  | ${dateMonth}`;
    } else {
      const variable = GCOSVariables[fileType][selectedVariable];
      attrib.innerHTML = `${dataType} GCOS | ${selectedReport} | ${variable} | DWD  | ${dateMonth}`;
    }
  }
};
// Used only by function 'editMapAttribution'
const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
